<template>
  <div class="books-page">
    <!-- Hero Section -->
    <section class="hero is-primary is-bold">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title is-size-1-touch">Books</h1>
        </div>
      </div>
    </section>

    <!-- Main Content Section -->
    <section class="section">
      <div class="container">
        <div class="columns is-multiline is-centered">
          <!-- Book Card -->
          <div class="column is-one-third-desktop is-half-tablet">
            <div class="card book-card">
              <div class="card-image">
                <figure class="image is-3by4">
                  <img src="https://images-na.ssl-images-amazon.com/images/I/41DKRKDOqIL._SX320_BO1,204,203,200_.jpg" alt="Polish Folk Songs and Dances" />
                </figure>
              </div>
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">Polish Folk Songs and Dances</p>
                    <p class="subtitle is-6">Ada Dziewanowska</p>
                    <div class="contact-info">
                      <p><strong>Phone:</strong> (414) 225-9440</p>
                      <p><strong>Fax:</strong> (414) 225-9442</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Repeat more cards as necessary -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Books",
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
/* Hero Section */
.hero-body {
  background: linear-gradient(to right, #93291e, #ed213a);
}

/* Book Card Styles */
.book-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
}

.card-content .title {
  font-size: 1.25rem;
  font-weight: bold;
}

.card-content .subtitle {
  color: #4a4a4a;
}

.contact-info p {
  margin: 5px 0;
}

.section {
  padding: 40px 20px;
}

/* Responsive Styling */
@media screen and (max-width: 768px) {
  .hero-body {
    padding: 2rem 1.5rem;
  }

  .book-card .card-content .title {
    font-size: 1.1rem;
  }

  .book-card .card-content .subtitle {
    font-size: 0.9rem;
  }

  .section {
    padding: 20px 10px;
  }
}
</style>
